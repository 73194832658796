<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Μετατροπή Ελβετικής Σε Ελληνική
            </h2>

            <br>
            <p class="display-1">
              ΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ
            </p><p class="subtitle-1">
              <ul>
                <li>Αίτηση - Υπεύθυνη Δήλωση του άρθρου 8 του Ν. 1599/86</li>
                <li>Φωτοαντίγραφο αστυνομικής ταυτότητας</li>
                <li>Φωτοαντίγραφο οποιουδήποτε ισχύοντος δημόσιου εγγράφου, εφόσον ο ενδιαφερόμενος δεν είναι Έλληνας υπήκοος</li>
                <li>Ισχύουσα άδεια Οδήγησης</li>
                <li>Μετάφραση τησ ξένης άδειας από το Υπουργείο Εξωτερικών ή από δικηγόρο</li>
                <li>Δύο πιστοποιητικά υγείας ιατρικής εξέτασης από οφθαλμίατρο και παθολόγο</li>
                <li>Αντίγραφο της απόδειξης πληρωμής που καταβλήθηκε στον παθολόγο και οφθαλμίατρο</li>
                <li>Αποδεικτικό πληρωμής 50,00€ για αρχική χορήγηση (κωδικός e-παραβόλου «21»)</li>
                <li>Αποδεικτικό πληρωμής 30,00€ για την εκτύπωση της άδειας οδήγησης (κωδικός e-παραβόλου«28»)</li>
                <li>Αποδεικτικό πάγιου τέλους χαρτοσήμου και εισφοράς υπέρ τρίτων</li>
                <li>Τρεις (3) πρόσφατες φωτογραφίες τύπου διαβατηρίου</li>
                <li>Αίτηση εκτύπωσης της άδειας οδήγησης</li>
                <br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/sideServices/Μετατροπή Ελβετικής Σε Ελληνική.png" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="white pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="12"
            md="12"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pdfs: [
        ['Εκτύπωση Αίτησης', '../static/μετατροπή-ελβετικής-σε-ελληνική-ΑΙΤ.pdf']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
